<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row no-gutter justify-center items-center">
                        <div class="vx-col hidden lg:block lg:w-1/2">
                            <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">登录</h4>
                                    <p>请登录你的账号。</p>
                                </div>
                                <vs-input
                                    v-validate="{ required: true, regex:/^1[3|4|5|7|8|9][0-9]{9}$/ }"
                                    data-vv-validate-on="blur"
                                    name="电话号码"
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label-placeholder="账号|电话号码"
                                    v-model="mobile"
                                    class="w-full no-icon-border"/>
                                <span class="text-danger text-sm">{{ errors.first('mobile') }}</span>

                                <vs-input
                                    data-vv-validate-on="blur"
                                    v-validate="'required|min:6|max:10'"
                                    type="password"
                                    name="密码"
                                    icon="icon icon-lock"
                                    icon-pack="feather"
                                    label-placeholder="密码"
                                    v-model="password"
                                    class="w-full mt-6 no-icon-border" />
                                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                                <div class="flex flex-wrap justify-between my-5">
                                    <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">记住我</vs-checkbox> -->
                                    <router-link to="/reset-password">找回密码</router-link>
                                </div>
                                <vs-button  type="border" @click="registerUser">注册新用户</vs-button>
                                <vs-button class="float-right" :disabled="!validateForm" @click="login">登录</vs-button>

                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            // mobile: '13812345672',
            // password: '123456',
            mobile: '',
            password: '',
            checkbox_remember_me: true
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any() && this.mobile != '' && this.password != '';
        },
    },
    methods: {
        login() {
            let vm = this;
            const payload = {
                checkbox_remember_me: this.checkbox_remember_me,
                userDetails: {
                    mobile: this.mobile,
                    password: this.password
                },
                notify: this.$vs.notify
            }
            this.$store.dispatch('auth/coachLogin', payload)
              .then(() => {
                  this.$router.push("/")
                  // this.$router.next()
                  })
              .catch(err => vm.$vs.notify({
                  title: "失败",
                  text: err,
                  color: 'danger',
                  position: 'top-right'
                }));
        },

        registerUser() {
            this.$router.push('/register');
        }
    }
}
</script>

<style lang="scss">
#page-login {
    .social-login {
        .bg-facebook {
          background-color: #1551b1;
        }
        .bg-twitter {
          background-color: #00aaff;
        }
        .bg-google {
          background-color: #4285F4;
        }
        .bg-github {
          background-color: #333;
        }
    }
}
</style>
